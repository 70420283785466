<app-navigation></app-navigation>
<main class="_contacto">
  <section class="_i">
    <div class="_header">
      <h2>No dudes en comunicarte con nosotros en caso de tener alguna duda</h2>
    </div>
    <div class="_body">
      <form class="_form_contact container" [formGroup]="contactoForm">
        <div class="row">
          <div class="col-lg-6 _1">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input matInput name="firstname" type="text" placeholder="Nombre" formControlName="nombres" required/>
              <mat-error> {{ errorFaltaCampo() }} </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 _2">
            <mat-form-field>
              <mat-label>Apellidos</mat-label>
              <input matInput name="lastname" type="text" formControlName="apellidos" required/>
              <mat-error> {{ errorFaltaCampo() }} </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 _3">
            <mat-form-field>
              <mat-label>Correo Electrónico</mat-label>
              <input matInput name="email" type="email" formControlName="correo" required/>
              <mat-error> {{ errorFaltaCampo() }} </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 _2">
            <mat-form-field>
              <mat-label>Mensaje</mat-label>
              <textarea matInput name="message" formControlName="mensaje" required></textarea>
              <mat-error> {{ errorFaltaCampo() }} </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 text-center">
            <button type="button" class="_btn _1" (click)="enviarForm()">Enviar</button>
          </div>
        </div>
      </form>
      <div class="_note">
        <p>
          O comunícate con nosotros a través de
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=573124951031"
            >WhatsApp</a
          >, o a travé de nuestra via telefónica al
          <a target="_blank" href="tel:6015801212">(57 - 1) 5801212</a>
        </p>
      </div>
    </div>
  </section>
  <section class="_cotizacion _ii">
    <div class="container-xl">
      <div class="row">
        <div class="col-lg-4 _1 offset-lg-8">
          <div class="_in">
            <h3 class="_h3 _1">Protege tu auto como cuidas de tus pacientes</h3>
            <a class="_btn _2">
              <span>Obtén más información</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <figure class="_bg">
      <img src="assets/img/bgs/bg_cotizacion.jpg" />
    </figure>
  </section>
</main>
<app-footer></app-footer>
