<div class="screen-background error-page">
    <div class="error-container">
        <img class="img-red-screen" src="assets/img/logo-negativo.svg" alt="">
        <p class="error-title">404</p>
        <p class="error-text">Parece que no hemos podido encontrar la página que buscas</p>
        <button mat-raised-button class="error-button" (click)="home()">
            <mat-icon>home</mat-icon>
            Volver al inicio
        </button>
    </div>
</div>