<a href="https://api.whatsapp.com/send?phone=573124951031" target="_blank" class="_whatsapp_fx">
  <img src="assets/img/icons/whatsapp_grey.png">
</a>
<footer class="_footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4 _1">
        <div class="_in">
          <a class="_brand">
            <img src="assets/img/brands/alseguros.svg">
          </a>
          <br>
          <p>Asesores latinos de Seguros LTDA</p>
          <p>Calle 25b # 69c-80 Oficina 104 Hotel Sheraton</p>
          <br>
          <p><b>Horario de atención</b></p>
          <p>Lunes a viernes de 09:00 am a 5:00 pm</p>
          <ul class="_icons">
            <li>
              <a href="https://www.instagram.com/alseguro/" target="_blank"><img src="assets/img/icons/instagram.png"></a>
            </li>
            <li>
              <a href="https://x.com/alseguros" target="_blank"><img src="assets/img/icons/x.png"></a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Alseguros/61555546957195/" target="_blank"><img src="assets/img/icons/facebook.png"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 _2">
        <div class="_in">
          <div class="_flex _1">
            <div class="_1">
              <figure class="_ccb">
                <img src="assets/img/brands/ccb.png">
              </figure>
            </div>
            <div class="_2">
                <p>Alseguros es una empresa afiliada a la Cámara de Comercio de Bogotá.</p>
            </div>
          </div>
          <div class="_flex _2">
            <div class="_1">
              <p>Autorizado por:</p>
            </div>
            <div class="_2">
              <figure class="_mapfre">
                <img src="assets/img/brands/mapfre.png">
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 _3">
        <div class="_in">
          <ul>
            <li><a routerLink="/">Inicio</a></li>
            <li><a routerLink="/nosotros">Sobre Nosotros</a></li>
            <li><a href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">Seguro de Responsabilidad civil médica</a></li>
            <li><a href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">Seguro de autos</a></li>
            <li><a href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">Servicio al cliente</a></li>
            <br>
            <li><a data-bs-toggle="modal" data-bs-target="#termsPopup">Términos y Condiciones</a></li>
            <li><a data-bs-toggle="modal" data-bs-target="#termsPopup">Aviso de privacidad</a></li>
            <li><a data-bs-toggle="modal" data-bs-target="#policyPopup">Política de Tratamiento De Datos Personales</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="_pays">
          <img class="_pse" src="assets/img/icons/foot_pse.png">
          <img class="_pse" src="assets/img/icons/foot_cardpay.png">
        </div>
        <div class="_note">
          <p>Asesores latinos de Seguros LTDA </p>
          <p>Copyright 2024 Todos los derechos reservados</p>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="footer-container d-none">
    <div class="row-a footer-content">
        <div class="footer-column">
            <div class="lineas-atencion">
                <p class="atencion-title">Atención al Cliente</p>
                <p class="atencion-phone">580 1212</p>
                <p>contacto@alseguros.co</p>
            </div>
            <div class="social-media">
                <a href="https://www.facebook.com/alseguro" target="_blank" class="social-media-icon">
                    <span class="mapfre-fb-icon">
                    </span>
                </a>
                <a href="https://twitter.com/AlsegurosCo" target="_blank" class="social-media-icon">
                    <span class="mapfre-twitter-icon">
                    </span>
                </a>
                <a href="https://www.instagram.com/alseguro/" target="_blank" class="social-media-icon">
                    <span class="mapfre-instagram-icon">
                    </span>
                </a>
            </div>
        </div>
        <div class="footer-column">
            <div class="horarios-atencion">
                <p class="atencion-title">Horario de atención</p>
                <p>Lunes a viernes de 10:00 am a 6:00 pm</p>
            </div>
            <div class="sede">
                <p>Alseguros</p>
                <p>Calle 25b # 69c-80</p>
                <p>Oficina 104 Hotel Sheraton</p>
            </div>
            <div class="social-media social-media-mobile">
                <a href="https://www.facebook.com/alseguro" target="_blank" class="social-media-icon">
                    <span class="mapfre-fb-icon">
                    </span>
                </a>
                <a href="https://twitter.com/AlsegurosCo" target="_blank" class="social-media-icon">
                    <span class="mapfre-twitter-icon">
                    </span>
                </a>
                <a href="https://www.instagram.com/alseguro/" target="_blank" class="social-media-icon">
                    <span class="mapfre-instagram-icon">
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>





<div class="modal fade _modal_1" id="termsPopup" tabindex="-1" aria-labelledby="termsPopupLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="_btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="_body-modal">
          <h2 class="_title">Términos y condiciones</h2>
          <p class="_text">Para ASESORES LATINOS DE SEGUROS LTDA es muy importante proteger la información y los datos de sus Usuarios. Este documento contiene nuestra política general de tratamiento de información y datos personales, y tiene como fin comunicar qué información recolectamos en la prestación de nuestros servicios y para qué finalidades, cómo la usamos, cuándo la compartimos y cómo la protegemos, así como los derechos que le asisten a los titulares de la información y los procedimientos para ejercerlos.</p>
          <p class="_text">Reconocemos la importancia de la seguridad, privacidad y confidencialidad de los datos personales que nuestros Usuarios, proveedores y empleados nos suministran a través de diversos canales.</p> 
          <p class="_text">Este documento es parte integral de los Términos y Condiciones de ASESORES LATINOS DE SEGUROS LTDA. Mediante la aceptación de los Términos y Condiciones el Usuario acepta igualmente las políticas aquí contenidas.</p>
          <p class="_text">Los titulares de los datos personales aceptan el tratamiento de sus datos personales conforme los términos de esta Política de Tratamiento De Datos Personales y nos autorizan a su tratamiento en los términos de esta política cuando proporciona los datos a través de nuestros puntos de atención o compra incluyendo Call Centers, o cuando adquiere o cuando usa cualquiera de nuestros productos o servicios, o cuando navega en nuestro sitio de web.</p>
          <br>
          <h4 class="_title">Información de Contacto</h4>
          <p class="_text">ASESORES LATINOS DE SEGUROS LTDA, es una sociedad constituida y domiciliada en Colombia, responsable del tratamiento de datos personales. Nuestros datos de contacto son:</p>
          <ul>
            <li><p>Dirección: Cra 25b # 69c-80 Lc 104</p></li>
            <li><p>E-mail: contacto@alseguros.co</p></li>
          </ul>
          <br>
          <br>
          <h4 class="_title">Información recolectada</h4>
          <p class="_text">La Información que se le solicita al usuario incluye, mas no se limita a:</p>
          <ul>
            <li><p>Correo electrónico, teléfono fijo y celulares</p></li>
            <li><p>Nombres y apellidos, dirección, fecha de nacimiento, género, número telefónico.</p></li>
            <li><p>Tipo y número de identificación.</p></li>
            <li><p>Nacionalidad y país de residencia.</p></li>
            <li><p>Estado civil y/o parentesco.</p></li>
            <li><p>Profesión u oficio y compañía en la que labora y cargo</p></li>
            <li><p>Información de vehículo, tales como placas, modelo, valor, lugar de circulación.</p></li>
            <li><p>Información sobre seguros cotizados.</p></li>
          </ul>
          <p>Con la finalidad de cumplir con las obligaciones del SARLAFT, los titulares de los datos personales deberán entregar información sensible, como datos biométricos (huella dactilar). Los titulares no estarán obligados en ningún evento a autorizar el tratamiento de datos sensibles, y por lo tanto la prestación de nuestros servicios no está condicionada a la entrega de esta información sensible, con excepción a la información requerida para diligenciamiento del SARLAFT que es un requisito legal para adquirir seguros, en caso de no entregarse esta información no podremos prestar nuestros servicios.</p>
          <p>Toda la información que recolectamos de nuestros clientes es compartida con las compañías de seguros por diferentes medios para obtener las propuestas o cotizaciones de seguros que, como intermediarios, presentaremos a los clientes prospectos. Por tal motivo, los titulares de datos personales, al aceptar esta política autorizan que su información personal que recolectamos y tratamos sea compartida con las compañías de seguros.</p>
          <p>La información suministrada permanecerá almacenada por el término máximo necesario para permitirnos el cumplimiento de las obligaciones legales y/o contractuales a su cargo especialmente en materia contable, contractual, fiscal y tributaria o por el tiempo necesario para atender las disposiciones aplicables a la materia de que se trate ya los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información.</p>
          <p>Presumimos la veracidad de la información suministrada y no verificamos, ni asumimos la obligación de verificar la veracidad, vigencia, suficiencia y autenticidad de los datos que nos sean proporcionados. Por tanto, no asumimos responsabilidad por daños y/o perjuicios de toda naturaleza que pudieran tener origen en la falta de veracidad, vigencia, suficiencia o autenticidad de la información, incluyendo daños y perjuicios que puedan deberse a la homonimia o a la suplantación de la identidad.</p>
          <p>Al inscribirse y/o navegar en nuestra página web, nuestros clientes y/o el visitante manifiesta expresamente que conoce estas políticas y las acepta, y otorga su consentimiento para que se utilice su información.</p>
          <br>
          <br>
          <h4 class="_title">Seguridad y confidencialidad</h4>
          <p>Tenemos establecidos políticas, procedimientos y estándares de seguridad de la información, cuyo objetivo es proteger y preservar la integridad, confidencialidad y disponibilidad de la información.</p>
          <br>
          <br>
          <h4 class="_title">Finalidades del tratamiento</h4>
          <p>Si usted nos proporciona Datos Personales, nos autoriza para usar esta información para las finalidades señaladas en la respectiva solicitud en concordancia con lo previsto en esta Política de Tratamiento De Datos Personales, y no procederemos a transferir o divulgar la misma fuera de la Sociedad salvo que (i)usted nos autorice expresamente a hacerlo,(ii) sea necesario para permitir a nuestros contratistas, proveedores o agentes prestar los servicios que les hemos encomendado, (iii) la utilicemos para proporcionarle nuestros productos o servicios, (iv) sea entregada a las entidades que prestan servicios de marketing en nuestro nombre o a otras entidades con las cuales tenemos acuerdos de mercadeo conjunto, (v) tenga relación con una fusión, consolidación, adquisición, desinversión u otro proceso de restructuración,(v) implementemos un contrato de transmisión de datos personales en los términos del Decreto 1377/13, o (vii) según sea requerido o permitido por la ley o para las finalidades desarrolladas en la presente Política de Tratamiento De Datos Personales.</p>
          <p>Al aceptar esta Política de Tratamiento De Datos Personales, nos autoriza para hacer tratamiento de la información recolectada para procesar, confirmar, cumplir y proveer nuestros servicios y/o productos, así como para promocionar y publicitar nuestras actividades, productos y servicios, realizar transacciones, efectuar reportes a las distintas autoridades administrativas de control y vigilancia nacionales o internacionales, autoridades de policía o autoridades judiciales, entidades bancarias y/o compañías aseguradoras, para fines administrativos internos y/o comerciales tales como, actualizar sus datos, investigación de mercados, encuestas de calidad de nuestros servicios, auditorías, reportes contables, análisis estadísticos, facturación, y ofrecimiento y/o reconocimiento de beneficios y nuevos productos de nuestros aliados.</p>
          <p>Con la aceptación de la presente Política de Tratamiento De Datos Personales, nuestros clientes, empleados y proveedores nos autorizan para hacerles llegar por distintos medios y vías (incluyendo correo electrónico, SMS o mensajes de texto, etc.) información de productos y servicios presentados en esta página web, y de ofertas de productos y servicios que creemos pueden serle de interés.</p>
          <p>Adicionalmente, nuestros clientes en su calidad de titulares de los datos recolectados, al aceptar esta política de privacidad, nos autorizan para:</p>
          <p>Utilizar la información recibida de ellos, para fines de mercadeo de sus productos y servicios, y de los productos y servicios de terceros con los que tengamos una relación de negocios.</p>
          <p>Compartir los datos personales con compañías de seguros con el fin de garantizar la prestación de nuestros servicios, la cotización de seguros y la expedición de las pólizas de seguros, así como compartir su información personal con compañías de seguros para que estos puedan ofrecer productos de interés a nuestros clientes y campañas de ventas cruzadas. En algunos de estos eventos, nosotros actuamos como encargados del tratamiento, siendo las compañías de seguros los responsables de tratamiento.</p>
          <p>Suministrar los datos personales a las autoridades de control y vigilancia de policía o judiciales, en virtud de un requerimiento legal o reglamentario y/o usar o revelar esta información y datos personales en defensa de sus derechos y/o su patrimonio en cuanto dicha defensa tenga relación con los productos y/o servicios contratados.</p>
          <p>Permitir el acceso a la información y datos personales a los auditores o terceros contratados para llevar a cabo procesos de auditoría interna o externa propios de la actividad comercial que desarrollamos.</p>
          <p>Consultar y actualizar los datos personales, en cualquier tiempo, con el fin de mantener actualizada dicha información.</p>
          <p>Contratar con terceros el almacenamiento y/o procesamiento de la información y datos personales para la correcta ejecución de los contratos celebrados con nosotros, bajo los estándares de seguridad y confidencialidad a los cuales estamos obligados.</p>
          <p>Transferir y transmitir los datos personales a países distintos de aquellos donde se recolecta la información, para lo cual procuraremos la protección de dichos datos conforme a los estándares de seguridad y confidencialidad establecidos en este documento.</p>
          <p>Transferir los datos personales a un eventual comprador de una de nuestras unidades de negocio, para lo cual brindaremos información oportuna a los titulares de los datos personales, para que éstos ejerzan los derechos de actualización, modificación y supresión de datos personales que les asisten conforme a la ley aplicable.</p>
          <p>En caso de venta, fusión, absorción, consolidación, cambio en el control societario, transferencia o cesión de activos a cualquier título, incluyendo, pero sin limitarse a la base de datos en la que conste la Información, reorganización o liquidación de la Sociedad, el titular de los datos nos autoriza a transferir la información.</p>
          <br>
          <br>
          <h4 class="_title">Modificaciones y vigencia de la Política de Tratamiento De Datos Personales</h4>
          <p>Podremos modificar en cualquier momento los términos y condiciones de estas Política de Tratamiento De Datos Personales. Si decidimos introducir algún cambio material a nuestras Política de Tratamiento De Datos Personales, se informará en la página web y publicando una versión actualizada de las políticas de privacidad. Las bases de datos tendrán vigencia indefinida, de conformidad las finalidades y usos de la información.</p>
          <p>Esta política fue modificada y publicada en nuestro sitio web el 30 de abril de 2020 y entra en vigencia a partir de la fecha de publicación.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade _modal_1" id="policyPopup" tabindex="-1" aria-labelledby="policyPopupLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="_btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="_body-modal">
          <h2 class="_title">Política de tratamiento de datos MAPFRE</h2>
          <h4>Condiciones generales</h4>
          <p>Al acceder a <a href="https://www.alseguros.co" target="_blank">www.alseguros.co</a> y a los materiales e información aquí contenidos, usted reconoce haber leído, comprendido y aceptado los términos y condiciones de uso que se describen a continuación, y acepta obligarse conforme a los mismos.</p>
          <h4 class="_title">Uso del sitio de Alseguros.co</h4>
          <p class="_text">El Sitio de ASESORES LATINOS DE SEGUROS LTDA, <a href="https://www.alseguros.co" target="_blank">www.alseguros.co</a> , tiene por objeto ofrecer información general sobre precios y condiciones de los seguros incluidos.</p>
          <p>ASESORES LATINOS DE SEGUROS LTDA lo autoriza a usted a consultar, revisar, usar y acceder a esta Página Web, únicamente para la eventual adquisición del Producto o Servicio.</p>
          <p>Sin embargo, la información contenida no debe ser interpretada como una solicitud u oferta y las condiciones están sujeto a confirmación final del Asegurador. Si bien intentamos proporcionar información actualizada, no asumimos la obligación de actualizar la información que se ofrece en este sitio.</p>
          <br>
          <h4>Contenido de la página</h4>
          <p>En la Página Web se ofrece información proporcionada por MAPFRE SEGUROS sobre los Productos o Servicios ofrecidos por tal Aseguradora. La Aseguradora es la única y exclusiva responsable de la información, las prestaciones, y la entrega de los Productos o Servicios presentadas en la presente Página Web. Las prestaciones y entrega de los Productos o Servicios de la Aseguradora se rigen por sus respectivas Condiciones Generales de Contratación y las leyes que les sean aplicables. La Sociedad no está obligada a la realización de las prestaciones de los Productos o Servicios de los Clientes ni a la entrega de estos Productos o Servicios.</p>
          <p>El Usuario, es el responsable por la veracidad, calidad y exactitud de los datos registrados en esta Página Web y por las consecuencias de incluir o colocar información errada, parcial, incompleta, fraccionada o que induzca a error, o sin autorización legal o del titular. Ni el Usuario ni el Asegurador podrán: (i) incluir o reportar información incompleta, difamatoria, falsa, inexacta, desactualizada, incomprobable y incomprensible; (ii) incluir material que sea obsceno, difamatorio, abusivo, amenazante u ofensivo para cualquier otro Usuario o cualquier otra persona o entidad.</p>
          <br>
          <h4>Política De Tratamiento De Datos Personales</h4>
          <p>Para usar o acceder a la presente página, los Usuarios deberán facilitar determinados datos que podrán ser considerados por las normas aplicables como datos personales tales como una dirección válida de correo electrónico y otro tipo de datos descritos en la Política de Tratamiento De Datos Personales.</p>
          <p>ASESORES LATINOS DE SEGUROS LTDA no se responsabiliza por imprecisiones, errores u omisiones en la información suministrada. Las comparaciones se realizarán de acuerdo a la información suministrada enteramente por el Usuario.</p>
          <p>El Usuario certifica que la información suministrada es precisa y libre de errores.</p>
          <p>Con la aceptación de estos Términos y Condiciones, el Usuario otorga su consentimiento y autorización libre, previo, y expreso, a ASESORES LATINOS DE SEGUROS LTDA. a almacenar, administrar, acceder, usar y tratar en cualquier manera su Información Personal para los fines establecidos en las Política de Tratamiento De Datos Personales y a suministrar o revelar su Información Personal a la Aseguradora y a terceras personas para los fines determinados en la Política de Tratamiento De Datos Personales. La Política de Tratamiento De Datos Personales hace parte integral de estos Términos y Condiciones.</p>
          <p>Usted también está de acuerdo que ASESORES LATINOS DE SEGUROS LTDA podrá utilizar con cualquier finalidad las ideas, conceptos, conocimientos o técnicas que usted presente a través de la página u otro canal de comunicación.</p>
          <br>
          <h4>Cambios en el Sitio</h4>
          <p>ASESORES LATINOS DE SEGUROS LTDA se reserva el derecho de modificar en cualquier momento el alcance y las funciones de su página web, de limitarla o cancelarla. Los trabajos de mantenimiento, la continuación del desarrollo y/o otras molestias pueden limitar y/o parcialmente interrumpir las posibilidades de uso. Bajo determinadas circunstancias se pueden producir pérdidas de datos. La Sociedad no asume por ello garantía alguna sobre la disponibilidad del servicio o la ausencia de molestias técnicas o pérdidas de datos.</p>
          <p>ASESORES LATINOS DE SEGUROS LTDA, puede modificar estos Términos y Condiciones en cualquier momento. Usted debe visitar esta Página Web periódicamente para revisar los Términos y Condiciones.</p>

          <br>
          <h4>Territorio</h4>
          <p>ASESORES LATINOS DE SEGUROS LTDA no asegura que esta Página Web y su contenido pueda ser legalmente usado o accedido fuera del territorio de Colombia. El acceso y uso puede no ser legal por ciertas personas o en ciertos países. Si usted usa o tiene acceso a esta Página Web desde un lugar ubicado fuera del territorio de Colombia, lo hace bajo su propio riesgo y es responsable del cumplimiento de las leyes aplicables en su jurisdicción.</p>
          <br>
          <h4>Terminación</h4>
          <p>ASESORES LATINOS DE SEGUROS LTDA se reserva el derecho, a su exclusiva discreción, de borrar toda la información que usted haya incluido en esta Página Web incluyendo sus datos personales y de terminar inmediatamente su acceso y uso a esta página web o a determinados servicios ante el incumplimiento por su parte de estos Términos y Condiciones o ante la imposibilidad de verificar o autenticar cualquier información que usted haya presentado en su forma de registro para acceder a esta Página Web.</p>


        </div>
      </div>
    </div>
  </div>
</div>