<app-navigation></app-navigation>
<section class="_resumen _i">
  <div class="container-sm">
    <div class="_in">
      <div class="_head">
        <h2 class="_title">¡Gracias por preferirnos!</h2>
      </div>
      <div class="_body">
        <div class="_content _i">
          <h4 class="_subtitle">Estos son tus datos:</h4>
          <div class="row">
            <div class="col-lg-4">
              <div class="_items _1">
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/user_icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1">{{informacionR?.poliza.cliente.persona.nombre}} {{informacionR?.poliza.cliente.persona.apellidos}}</p>
                  </div>
                </div>
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/phone_icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1">{{informacionR?.poliza.cliente.celular}}</p>
                  </div>
                </div>
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/mail_icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1">{{informacionR?.poliza.cliente.correo}}</p>
                  </div>
                </div>
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/location_icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1"> {{informacionR?.hospital}}</p>
                    <p class="_2">{{informacionR?.ciudad.nombre_ciudad}}, {{informacionR?.ciudad.departamento}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="_items _2">
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/medicina-icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1">Especialidad:</p>
                    <p class="_2">{{informacionR?.especialidad_medico.nombre_especialidad}}</p>
                    <p class="_1">Tipo de Procedimiento:</p>
                    <p class="_2">{{informacionR?.clase_rc_medico.clase_medico.nombre_clase}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="_items _2">
                <div class="_item">
                  <figure class="_icon">
                    <img src="assets/img/icons/certificado-icon.png" class="_img">
                  </figure>
                  <div class="_data">
                    <p class="_1">Póliza de responsabilidad civil médica MAPFRE:</p>
                    <p class="_2">{{informacionR?.clase_rc_medico.valor_rc | currency : '$' : 'symbol' : '1.0-0' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="_content _ii">
          <div class="_card_2">
            <div class="_inside">
              <p>Estamos en proceso de revisar la documentación que nos enviaste. Pronto te contactaremos, y si todo está correcto, recibirás un enlace de pago. Una vez realizado el pago, podrás descargar tu póliza de responsabilidad civil médica.</p>
              <div class="_icons">
                <div class="_icon">
                  <img src="assets/img/icons/pse.png" class="_img">
                  <p>PSE</p>
                </div>
                <div class="_icon">
                  <img src="assets/img/icons/cardpay.png" class="_img">
                  <p>Tarjeta de crédito</p>
                </div>
              </div>
            </div>
          </div>
          <div class="_action">
            <a class="_btn _outline _1" routerLink="/poliza">
              <span>Conoce más sobre tu póliza</span>
            </a>
          </div>

          <div class="_note">
            <div class="_flex">
              <div class="_info">
                <p>No dudes en comunicarte con nosotros en caso de tener alguna duda.</p>
              </div>
              <div class="_rrss">
                <a class="_rs" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
                  <img src="assets/img/icons/whatsapp.png" class="_img">
                </a>
                <a class="_rs" href="mailto:contacto@alseguros.co" target="_blank">
                  <img src="assets/img/icons/mail.png" class="_img">
                </a>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
</section>


<app-footer></app-footer>