<app-navigation (scroll)="onWindowScroll($event);" class="nav home"></app-navigation>
<div class="background d-none"></div>
<div class="especialidad-container d-none">
  <h1>Desarrolla tu profesión médica con tranquilidad, por medio de la póliza de responsabilidad civil médica.
  </h1>
  <h2>Descubre todas las opciones que tenemos para ti en cotiza completamente gratis.</h2>
  <!--agregado para hacer pruebas-->
  <app-formulario-cotizacion (formularioCotizacionFinalizado)="formularioCotizacionFinalizado($event)" ></app-formulario-cotizacion>
</div>
<section class="_home _i">
  <aside class="_form d-none d-lg-block">
    <div class="_in container">
      <div class="_stick">
        <app-formulario-cotizacion (formularioCotizacionFinalizado)="formularioCotizacionFinalizado($event)" ></app-formulario-cotizacion>
      </div>
    </div>
  </aside>
  <div class="_i">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 _1">
                <div class="_in">
                    <div class="_info">
                        <h1 class="_w-100 _title">Desarrolla tu profesión médica con tranquilidad, por medio de la póliza de responsabilidad civil médica.</h1>
                        <p class="_text">Cotiza completamente gratis y descubre todas las opciones que tenemos para ti.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 _2">
              <div class="d-lg-none _form-resp">
                <app-formulario-cotizacion (formularioCotizacionFinalizado)="formularioCotizacionFinalizado($event)" ></app-formulario-cotizacion>
              </div>
            </div>
        </div>
    </div>
    <figure>
        <img src="assets/img/bgs/detail_radio.png">
    </figure>
  </div>
  <div class="_ii">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 _1">
          <div class="_content">
            <div class="_head">
              <h1>Nuestros <br>beneficios</h1>
            </div>
            <div class="_body">
              <div class="_pre-card _1">
                <div class="_inside">
                  <div class="_card-1 _1">
                    <div class="_in _1">
                      <img src="assets/img/icons/cart.png">
                      <h2>Compra fácil</h2>
                    </div>
                    <div class="_in _2">
                      <p>Cotiza y compra tu <a href="#">Seguro de Responsabilidad Civil Médica</a> en línea, de forma segura y sin complicaciones y al instante</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_pre-card _2">
                <div class="_inside">
                  <div class="_card-1 _1">
                    <div class="_in _1">
                      <img src="assets/img/icons/globo.png">
                      <h2>Ahorra tu tiempo</h2>
                    </div>
                    <div class="_in _2">
                      <p>Accede desde cualquier dispositivo para <a href="#">adquirir tu seguro de responsabilidad civil médica</a> en pocos clics.</p>
                    </div>
                  </div>
                </div>
                  
              </div>
              <div class="_pre-card _3">
                <div class="_inside">
                  <div class="_card-1 _1">
                    <div class="_in _1">
                      <img src="assets/img/icons/callcenter.png">
                      <h2>Ayuda a un clic</h2>
                    </div>
                    <div class="_in _2">
                      <p>No dudes en <a href="#">contactarnos</a>, siempre estaremos dispuestos a ayudarte y responder todas las dudas que tengas.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <figure>
      <img src="assets/img/bgs/bg_home_ii.png">
    </figure>
  </div>
  <div class="_iii">
    <div class=" _marquee">
      <div class="_track">
        <div class="_content">
          <div class="_brands">
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brad_occidente.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_marly.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_meredi.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_rosario.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_santafe.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_sofia.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_universitaria.png">
              </figure>
            </a>

            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brad_occidente.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_marly.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_meredi.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_rosario.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_santafe.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_sofia.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_universitaria.png">
              </figure>
            </a>

            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brad_occidente.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_marly.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_meredi.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_rosario.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_santafe.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_sofia.png">
              </figure>
            </a>
            <a class="_brand">
              <figure>
                <img src="assets/img/brands/brand_universitaria.png">
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="_home _ii">
  <div class="container">
      <div class="row _1">
          <div class="col-md-6 _1">
              <div class="_in">
                  <div class="_info">
                      <h1>¿Qué es la póliza de Responsabilidad Civil Profesional Médica de MAPFRE?</h1>
                      <p>La póliza de Responsabilidad Civil Profesional Médica de MAPFRE, es un medio de protección para los profesionales de la salud que deseen amparar los riesgos de Responsabilidad Civil Profesional derivados de los daños que puedan causar en el desarrollo y ejercicio de su actividad profesional.</p>
                      <a class="_btn _1" routerLink="/poliza">
                          <span>Aprende más sobre tu póliza</span>
                      </a>
                  </div>
              </div>
          </div>
          <div class="col-md-6 _2">
              <div class="_in">
                  <figure>
                      <img src="assets/img/pics/pic_1.png">
                  </figure>
              </div>
          </div>
      </div>
      <div class="row _2">
          <div class="col-md-6 _1">
              <div class="_in">
                  <figure>
                      <img src="assets/img/pics/pic_2.png">
                  </figure>
              </div>
          </div>
          <div class="col-md-6 _2">
              <div class="_in">
                  <div class="_info">
                      <h1>Un referente en el mundo de los seguros desde 1974</h1>
                      <p>Hemos ofrecido soluciones de seguridad efectivas y confiables, lo que nos ha permitido crecer y evolucionar, abriendo nuevos caminos a clientes como tu, y a alianzas con líderes del sector como Mapfre, para poder ofrecerte los mejores servicios y seguridad para todos los aspectos de tu vida.</p>
                      <a class="_btn _1" routerLink="/nosotros">
                        <span>Conoce más sobre nosotros</span>
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <figure>
    <img src="assets/img/bgs/waves_2.png">
  </figure>
</section>



<div class="mapfre-container home d-none">
    <div class="experiencias">
        <div class="flex-container home">
            <div class="lineas-container">
                <div class="franja comentarios rojo"></div>
                <div class="linea rojo"></div>
            </div>
            <div class="titulos-home blanco">
                <h1 class="">Nuestros clientes</h1>
            </div>
            <div class="carousel-container">
                <owl-carousel-o [options]="customOptions" class="comments">
                    <ng-template carouselSlide *ngFor="let comment of arrayComments">
                        <div class="comment-item">
                            <div class="user-info">
                                <div class="user-icon">
                                    <span [ngClass]="comment.userIcon"></span>
                                </div>
                                <p class="user-name"> <strong>{{comment.userName}}</strong></p>
                                <div class="puntuacion">
                                    <img [src]="comment.userPuntuacion.puntuacionPath1">
                                    <img [src]="comment.userPuntuacion.puntuacionPath2">
                                    <img [src]="comment.userPuntuacion.puntuacionPath3">
                                    <img [src]="comment.userPuntuacion.puntuacionPath4">
                                    <img [src]="comment.userPuntuacion.puntuacionPath5">
                                </div>
                            </div>
                            <div class="comment">
                                <p> {{comment.userComment}} </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="flex-container home">
        <div class="lineas-container">
            <div class="franja beneficios amarillo"></div>
            <div class="linea amarillo"></div>
        </div>
        <div class="titulos-home">
            <h1 class="">Nuestros beneficios</h1>
        </div>
        <div class="white-container">
            <div *ngFor="let ben of arrayBeneficios" class="beneficio-container">
                <div class="beneficio-icon">
                    <span [ngClass]="ben.icon"></span>
                </div>
                <div class="beneficio-descripcion">
                    <h3 class="beneficio-subtitle"> {{ben.title}} </h3>
                    <p class="mat-body"> {{ben.description}} </p>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-container home">
        <div class="lineas-container">
            <div class="franja contacto rojo"></div>
            <div class="linea rojo"></div>
        </div>
        <div class="titulos-home blanco">
            <h1>Contáctanos</h1>
        </div>
        <div class="white-container contacto">
            <h2>Atención al cliente</h2>
            <h1>(57 - 1) 5801212</h1>
            <p class="mat-body">contacto@alseguros.co</p>
        </div>

    </div>
</div>
<app-footer></app-footer>