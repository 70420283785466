import { Injectable } from '@angular/core';

import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InformacionContacto } from '../interfaces/Contacto';
@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  private path = environment.api;

  constructor(private http: HttpClient) { }

  enviarMensajeDeContacto(informacionContacto: InformacionContacto){
    const url = this.path +'/polizas-medicos/correo/contacto';
    this.http.post(url, informacionContacto).subscribe((rta) => {});
  }
}
