import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject, HostListener  } from '@angular/core';
import Splide from '@splidejs/splide';



@Component({
  selector: 'app-poliza',
  templateUrl: './poliza.component.html',
  styleUrls: ['./poliza.component.scss']
})
export class PolizaComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngAfterViewInit(): void {
    new Splide('.splide', {
      type: 'loop',
      rewind: true,
      arrows: false,
      perPage: 3,
      padding: { left: '2rem', right: '20%' },
      pagination: false,
      autoplay: false,
      interval: 5000,
      breakpoints: {
        767: {
          perPage: 2,
        },
        575: {
          perPage: 1,
      padding: { left: '2rem', right: '30%' },
        },
      }
    }).mount();
  }

  ngOnInit(): void {
  }

  enrutarAPaginaCotizacion() {
    this.router.navigate(['mostrar-cotizacion'], {
      relativeTo: this.route.parent,
    });
  }

  formularioCotizacionFinalizado(finalizado: boolean) {
    if (finalizado) {
      this.enrutarAPaginaCotizacion();
    }
  }
}
