<header class="_header">
  <div class="container">
    <div class="_flex">
      <a class="_action_menu d-lg-none" (click)="toggleMenu()" [ngClass]="{'_on': !menuIsActive}">
        <div class="_in">
          <span class="_1"></span>
          <span class="_2"></span>
          <span class="_3"></span>
        </div>
      </a>
      <nav class="nav d-none d-lg-flex">
        <ul>
          <li><a routerLink="/" class="_item"><span>Inicio</span></a></li>
          <li><a routerLink="/nosotros" class="_item"><span>Nosotros</span></a></li>
          <li><a routerLink="/contacto" class="_item"><span>Contacto</span></a></li>
        </ul>
      </nav>
      <a class="_brand" href="/">
        <img src="assets/img/brands/mapfre.png">
      </a>
      <nav class="nav d-none d-lg-flex">
        <ul>
          <div class="_actions_collapse">
            <button
              type="button"
              class="_btn _1 _action_button_services">
              Cotiza tus seguros
            </button>
            <div class="collapse collapse_1">
              <div class=" card-body">
                <div class="_flex">
                  <a class="_icon" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
                    <img src="assets/img/icons/sombr.png">
                    <p>Educativo</p>
                  </a>
                  <a class="_icon" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
                    <img src="assets/img/icons/icon_carro.png">
                    <p>Autos</p>
                  </a>                  
                </div>
              </div>
            </div>
          </div>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-4">
      </div>
      <div class="col-lg-4">
      </div>
    </div>
  </div>
</header>
<nav class="navigation-container d-none">
  <div class="navigation-content">
    <a class="home-logo" routerLink="/"></a>
    <div class="navigation-icons-container">
      <a class="navigation-icon" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
        <span class="mapfre-whatsapp-icon"></span>
      </a>
      <a type="button" class="navigation-icon mail" href="mailto:contacto@alseguros.co" >
        <span class="mapfre-email-icon"></span>
      </a>
      <a #tooltip="matTooltip" matTooltip="{{correo}}" type="button" class="navigation-mail" [cdkCopyToClipboard]="value"
      (click)="tooltip.show()">
      {{value}}
    </a>
      <a class="navigation-icon phone" href="tel:+5715801212">
        <span class="mapfre-phone-icon"></span>
      </a>
    </div>
  </div>
</nav>

<aside class="_supermenu" [ngClass]="{'_on': !menuIsActive}">
  <div class="_in">
    <div class="_items _1">
      <a class="_link" routerLink="/">Inicio</a>
      <a class="_link" routerLink="/nosotros">Nosotros</a>
      <a class="_link" routerLink="/contacto">Contacto</a>
      <br>
      <a class="_link" routerLink="/poliza">Calcula tu seguro</a>
      <div class="_icons">
        <a class="_icon-link" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
          <img class="_icon" src="assets/img/icons/icon_cruz.png">
        </a>
        <a class="_icon-link" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
          <img class="_icon" src="assets/img/icons/icon_carro.png">
        </a>
        <a class="_icon-link" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
          <img class="_icon" src="assets/img/icons/sombr.png">
        </a>

      </div>
    </div>
    <div class="_items _2">
      <div class="_icons">
        <a class="_icon-link">
          <img class="_icon" src="assets/img/icons/facebook.png">
        </a>
        <a class="_icon-link">
          <img class="_icon" src="assets/img/icons/instagram.png">
        </a>
        <a class="_icon-link">
          <img class="_icon" src="assets/img/icons/x.png">
        </a>
      </div>
    </div>
  </div>
</aside>