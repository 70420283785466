import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject, HostListener  } from '@angular/core';
import Splide from '@splidejs/splide';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngAfterViewInit(): void {
    new Splide('.splide', {
      type: 'fade',
      rewind: true,
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 5000,
      breakpoints: {
        991: {},
        767: {},
      }
    }).mount();
  }
  
  ngOnInit(): void {
  }

  enrutarAPaginaCotizacion() {
    this.router.navigate(['mostrar-cotizacion'], {
      relativeTo: this.route.parent,
    });
  }

  formularioCotizacionFinalizado(finalizado: boolean) {
    if (finalizado) {
      this.enrutarAPaginaCotizacion();
    }
  }

}
