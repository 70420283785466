import { Component, OnInit } from '@angular/core';
import { ContactoService } from '../services/contacto.service';
import { InformacionContacto } from '../interfaces/Contacto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  contactoForm: FormGroup;
  informacionContacto: InformacionContacto;

  constructor(private formBuilder: FormBuilder, private contactoService: ContactoService) { }

  ngOnInit(): void {
    this.contactoForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      correo: ['', Validators.required],
      mensaje: ['', Validators.required]
    })
  }

  errorFaltaCampo(){
    return 'Ingrese un valor'
  }

  enviarForm(){
    this.contactoService.enviarMensajeDeContacto(
      {
        nombres: this.contactoForm.controls['nombres'].value,
        apellidos: this.contactoForm.controls['apellidos'].value,
        correo: this.contactoForm.controls['correo'].value,
        mensaje: this.contactoForm.controls['mensaje'].value
      }
    )
    this.contactoForm.reset();
  }
}
