<app-navigation></app-navigation>

<div class="step-form _indentificacion" >
  <div *ngIf="FormIdentifiacion">
    <section class="_identificacion _1">
      <div class="container-xl">
        <div class="_header text-center">
          <h2 class="_title">Confirma tus datos</h2>
          <p>Confirma tus datos o modifica y completa la información restante.</p>
        </div>
        <div class="_body">

          <form [formGroup]="identificacionForm" class="_form">
            <div class="row _1">
              <div class="col-lg-6">
                <div class="_title">
                  <h4>Tu Información Personal</h4>
                </div>
                <div class="_items">
                  <div class="_item-info">
                    <div class="_icon">
                      <img class="_icon" src="assets/img/icons/location_icon.png" >
                    </div>
                    <div class="_name">
                      <mat-form-field appearance="outline">
                        <input
                          tab-directive
                          type="text"
                          placeholder="Ciudad"
                          matInput
                          formControlName="ciudad"
                          [matAutocomplete]="autocompleteCiudad"
                          required
                          [value]="nombreCiudad"
                        />
                        <mat-label>Ciudad donde trabajas</mat-label>
                        <mat-autocomplete
                          #autocompleteCiudad="matAutocomplete"
                          autoActiveFirstOption="true"
                          (optionSelected)="escribirCiudad()"
                        >
                          <mat-option
                            *ngFor="let ciudad of ciudadesList | async"
                            [value]="ciudad"
                          >
                            {{ ciudad.nombre_ciudad }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="_item-info">
                    <div class="_icon" style="opacity:0;">
                      <img class="_icon" src="assets/img/icons/location_icon.png" >
                    </div>
                    <div class="_name">
                      <mat-form-field appearance="outline">
                        <mat-label>¿Dónde trabajas?</mat-label>
                        <input
                          matInput
                          placeholder="¿Dónde trabajas?"
                          formControlName="lugarTrabajo"
                        />
                        <mat-error> {{ errorLugarTrabajo() }} </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                      
                      
                </div>
              </div>
              <div class="col-lg-6">
                <div class="_title">
                  <h4>Tu Información Profesional</h4>
                </div>
                <div class="_items">
                  <div class="_item-pro">
                    <div class="_item-name">
                      <p class="_p">Especialidad</p>
                    </div>
                    <div class="_item-descripcion">
                      <p class="_p">{{especialidad}}</p>
                    </div>
                  </div>
                  <div class="_item-pro">
                    <div class="_item-name">
                      <p class="_p">Tipo de procedimiento</p>
                    </div>
                    <div class="_item-descripcion">
                      <p class="_p">{{clase}}</p>
                    </div>
                  </div>
                  <div class="_item-pro">
                    <div class="_item-name">
                      <p class="_p">¿Realizas medicina domiciliaria?</p>
                    </div>
                    <div class="_item-descripcion">
                      <div class="custom-radio yes-no">
                        <div class="custom-radio-options yes-no">
                          <label class="custom-radio-container yes-no-question">
                            <input
                              type="radio"
                              name="preguntaSiete"
                              formControlName="preguntaSiete"
                              value="1"
                              class="custom-radio-input-yes-no"
                              (change)="onRadioChange($event)"
                            />
                            <span class="custom-radio-text">Sí</span>
                          </label>
                          <label class="custom-radio-container yes-no-question">
                            <input 
                              type="radio"
                              name="preguntaSiete"
                              formControlName="preguntaSiete"
                              value="2"
                              class="custom-radio-input-yes-no"
                              (change)="onRadioChange($event)"
                            />
                            <span class="custom-radio-text">No</span>
                          </label>
                          <span #bit class="_bit">
                            <span class="_yes">Si</span>
                            <span class="_no">No</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="_note">
                    <div class="_note_yesno" #noteYesNo>
                      <div class="flex">
                        <div class="_1">
                          <img src="assets/img/icons/icon_alert_white.png">
                        </div>
                        <div class="_2">
                          <p><small>Si proporciona servicios de emergencia/urgencia a domicilio, nuestro equipo revisará la información enviada y le notificará sobre la posibilidad de emitir la póliza.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_title">
                  <h4>Información Sobre tu Póliza</h4>
                </div>
                <div class="_items">
                  <div class="_item-pro _2">
                    <div class="_item-name">
                      <p class="_p">Póliza de responsabilidad civil médica MAPFRE:</p>
                    </div>
                    <div class="_item-descripcion">
                      <p class="_p">{{valorCotizacion.valor_rc | currency : '$' : 'symbol' : '1.0-0'}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row _2">
              <div class="col-12 text-center">
                <button mat-raised-button class="_btn-form _1" [disabled]="identificacionForm.invalid" (click)="adjuntarDocumentos()">
                  Obten tu póliza
                </button>
                <p>Dentro de poco recibirás un Correo electrónico con todos los detalles de tu cotización. No dudes en comunicarte con nosotros a través de whatsapp o llamando al 601 580 1212 en caso de tener alguna duda.</p>
              </div>
            </div>
          </form>


            
        </div>
      </div>
    </section>
  </div>
</div>

<app-footer></app-footer>

