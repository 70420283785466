import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { Popover, Tooltip } from 'bootstrap';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'mapfre-medicos';
  popover: Popover;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    // Se puede agregar lógica adicional aquí si es necesario.
  }

  ngAfterViewInit() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl);
    });

    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach((popoverTriggerEl) => {
      new Popover(popoverTriggerEl);
    });

    // Agregar el listener para el botón de toggle
    const actionButton = document.querySelector('._action_button_services');
    if (actionButton) {
      actionButton.addEventListener('click', this.toggleCollapse.bind(this));
    }
  }

  // Método para alternar el popover
  togglePopover() {
    if (this.popover) {
      this.popover.toggle();
    }
  }

  // Método para alternar el colapso
  toggleCollapse() {
    const collapseElement = document.querySelector('.collapse_1');
    const actionButton = document.querySelector('._action_button_services');
    if (collapseElement) {
      collapseElement.classList.toggle('show');
    }
    if (actionButton) {
      actionButton.classList.toggle('_active');
    }
  }
}
