<app-navigation></app-navigation>
<main class="_poliza">
	<section class="_i">
		<div class="container-xl">
			<div class="row _just-resp">
				<div class="col-12 _1 d-xl-none">
					<figure>
						<img src="assets/img/pics/poliza.jpg">
					</figure>
				</div>
			</div>
			<div class="_head">
				<h2>¿Qué es la póliza de Responsabilidad Civil Profesional Médica de MAPFRE?</h2>
				<p>La póliza de Responsabilidad Civil Profesional Médica de MAPFRE, es un medio de protección para los profesionales de la salud que deseen amparar los riesgos de Responsabilidad Civil Profesional derivados de los daños que puedan causar en el desarrollo y ejercicio de su actividad profesional.</p>
			</div>
			<div class="_body">
				<div class="row _1">
					<div class="col-xl-6 _1 d-none d-xl-block">
						<figure>
							<img src="assets/img/pics/poliza.jpg">
						</figure>
					</div>
					<div class="col-xl-6 _2 ">
						<div class="_in d-none d-xl-block">
							<h2>¿Qué cubre mi póliza?</h2>
							<div class="_items">
								<div class="_item">
									<div class="_inside">
										<div class="_in-inside">
											<p><strong>Responsabilidad civil actos médicos</strong></p>	
											<p>100% del valor asegurado</p>
										</div>
									</div>
								</div>
								<div class="_item">
									<div class="_inside">
										<div class="_in-inside">
											<p><strong>Gastos de defensa</strong></p>	
											<p>40% del valor asegurado</p>
										</div>
									</div>
								</div>
								<div class="_item">
									<div class="_inside">
										<div class="_in-inside">
											<p><strong>Responsabilidad Civil acto médico auxilio</strong></p>	
											<p>100% del valor asegurado</p>
										</div>
									</div>
								</div>
								<div class="_item">
									<div class="_inside">
										<div class="_in-inside">
											<p><strong>Asistencia médica emergencia</strong></p>	
											<p>100% del valor asegurado</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="_in d-xl-none">
							<h2>¿Qué cubre mi póliza?</h2>
							<div class="splide _items">
								<div class="splide__track">
									<div class="splide__list">
										<div class="splide__slide">
											<div class="_item">
												<div class="_inside">
													<div class="_in-inside">
														<p><strong>Responsabilidad civil actos médicos</strong></p>	
														<p>100% del valor asegurado</p>
													</div>
												</div>
											</div>
										</div>
										<div class="splide__slide">
											<div class="_item">
												<div class="_inside">
													<div class="_in-inside">
														<p><strong>Gastos de defensa</strong></p>	
														<p>40% del valor asegurado</p>
													</div>
												</div>
											</div>
										</div>
										<div class="splide__slide">
											<div class="_item">
												<div class="_inside">
													<div class="_in-inside">
														<p><strong>Responsabilidad Civil acto médico auxilio</strong></p>	
														<p>100% del valor asegurado</p>
													</div>
												</div>
											</div>
										</div>
										<div class="splide__slide">
											<div class="_item">
												<div class="_inside">
													<div class="_in-inside">
											<p><strong>Asistencia médica emergencia</strong></p>	
											<p>100% del valor asegurado</p>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row _2">
					<div class="col-12 text-center">
						<a class="_btn _1 m-2-auto" routerLink="/nosotros">
							<span>Aprende más sobre nosotros</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="_ii">
		<div class="container-lg">
			<div class="_body">
				<div class="row _1">
					<div class="col-lg-6 _1">
						<div class="_in">
							<h3>¿Cómo emito mi póliza de Responsabilidad Civil Médica?</h3>
							<p>Para iniciar el proceso de emisión de la póliza de responsabilidad civil médica, es fundamental contar con la siguiente documentación a la mano:</p>
							<ul>
								<li><span class="_1"><img src="assets/img/icons/check.png"></span> <p><span>Copia legible de la cédula de ciudadanía</span></p></li>
								<li><span class="_1"><img src="assets/img/icons/check.png"></span> <p><span>Tarjeta profesional</span></p></li>
								<li><span class="_1"><img src="assets/img/icons/check.png"></span> <p><span>Certificados de especialidades</span> <span>(que incluyan diplomas y actas de grado)</span></p> </li>
							</ul>
							<p>Una vez que haya obtenido la cotización de su póliza a través de nuestra página, podrá dar continuidad al proceso de emisión. Esto implica completar su información básica y adjuntar la documentación mencionada anteriormente. Después de cargar la información, uno de nuestros asesores la revisará minuciosamente. En caso de que falte algún documento o la información esté incompleta, nos pondremos en contacto con usted para aclarar cualquier detalle.</p>
							<p>Si toda la información está completa, procederemos a emitir su póliza. Le enviaremos un enlace de pago a través de WhatsApp. Una vez que realice el pago correspondiente, le enviaremos el certificado por el mismo medio.</p>
						</div>
					</div>
					<div class="col-lg-6 _2">
						<figure>
							<img src="assets/img/pics/students.jpg">
						</figure>
					</div>
				</div>
				<div class="row _2">
					<div class="col-12">
						<p class="_big">Si en algún momento del proceso surge alguna duda, no dude en ponerse en contacto con nosotros. Estamos aquí para ayudarle en todo el proceso</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="_iii">
		<div class="container-lg">
			<div class="_content-form">
				<app-formulario-cotizacion (formularioCotizacionFinalizado)="formularioCotizacionFinalizado($event)" ></app-formulario-cotizacion>
			</div>
		</div>
		<figure class="_bg">
			<img src="assets/img/bgs/bg_Grandient_effect.png">
		</figure>
	</section>
	<section class="_iv">
		<div class="container-lg">
			<div class="_head">
				<h1	>Preguntas sobre la Póliza de Responsabilidad Civil Médica</h1	>
			</div>
			<div class="_body">
				<div class="accordion" id="accordionFaqs">
				  <div class="accordion-item">
				    <h2 class="accordion-header">
				      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseI" aria-expanded="false" aria-controls="collapseI">
				      	<span class="_icon">
				      		<span class="_line _1"><img src="assets/img/icons/plus.png"></span>
				      		<span class="_line _2"><img src="assets/img/icons/minus.png"></span>
				      	</span>
				      	<span class="_text">¿Qué es una póliza de responsabilidad civil?</span>
				      </button>
				    </h2>
				    <div id="collapseI" class="accordion-collapse collapse" data-bs-parent="#accordionFaqs">
				      <div class="accordion-body">
				        <p>Una póliza de responsabilidad civil tiene como objetivo cubrir los daños y perjuicios que el asegurado ocasione a terceros (personas) como consecuencia de actos causados involuntariamente por parte del asegurado.</p>
				      </div>
				    </div>
				  </div>
				  <div class="accordion-item">
				    <h2 class="accordion-header">
				      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseII" aria-expanded="false" aria-controls="collapseII">
				      	<span class="_icon">
				      		<span class="_line _1"><img src="assets/img/icons/plus.png"></span>
				      		<span class="_line _2"><img src="assets/img/icons/minus.png"></span>
				      	</span>
				      	<span class="_text">¿Qué es la Póliza de Responsabilidad Civil Médica?</span>
				      </button>
				    </h2>
				    <div id="collapseII" class="accordion-collapse collapse" data-bs-parent="#accordionFaqs">
				      <div class="accordion-body">
				        <p>Cuando ejerces tu profesión estás obligado a preservar los derechos de tus pacientes como la vida, la salud, las buenas condiciones físicas o mentales y la integridad corporal. Cuando vulneras alguno de estos derechos, tu paciente o los afectados pueden reclamar una indemnización.</p>
				      </div>
				    </div>
				  </div>
				  <div class="accordion-item">
				    <h2 class="accordion-header">
				      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIII" aria-expanded="false" aria-controls="collapseIII">
				      	<span class="_icon">
				      		<span class="_line _1"><img src="assets/img/icons/plus.png"></span>
				      		<span class="_line _2"><img src="assets/img/icons/minus.png"></span>
				      	</span>
				      	<span class="_text">¿Qué cubre la Póliza de Responsabilidad Civil Médica?</span>
				      </button>
				    </h2>
				    <div id="collapseIII" class="accordion-collapse collapse" data-bs-parent="#accordionFaqs">
				      <div class="accordion-body">
				        <p>Cuando ejerces tu profesión estás obligado a preservar los derechos de tus pacientes como la vida, la salud, las buenas condiciones físicas o mentales y la integridad corporal. Cuando vulneras alguno de estos derechos, tu paciente o los afectados pueden reclamar una indemnización.</p>
				      </div>
				    </div>
				  </div>
				  <div class="accordion-item">
				    <h2 class="accordion-header">
				      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIV" aria-expanded="false" aria-controls="collapseIV">
				      	<span class="_icon">
				      		<span class="_line _1"><img src="assets/img/icons/plus.png"></span>
				      		<span class="_line _2"><img src="assets/img/icons/minus.png"></span>
				      	</span>
				      	<span class="_text">¿Cuál es el precio de Póliza de Responsabilidad Civil Médica?</span>
				      </button>
				    </h2>
				    <div id="collapseIV" class="accordion-collapse collapse" data-bs-parent="#accordionFaqs">
				      <div class="accordion-body">
				        <p>Cuando ejerces tu profesión estás obligado a preservar los derechos de tus pacientes como la vida, la salud, las buenas condiciones físicas o mentales y la integridad corporal. Cuando vulneras alguno de estos derechos, tu paciente o los afectados pueden reclamar una indemnización.</p>
				      </div>
				    </div>
				  </div>
				</div>
			</div>
		</div>
	</section>
</main>
<app-footer></app-footer>