<form [formGroup]="contacto" class="_form _1">
  <div class="_head">
    <h4>Cotiza tu póliza de <br>Responsabilidad civil médica</h4>
    <div class="_steps">
      <span class="_step _1">Información médica</span> 
      <span class="_step _1">></span> 
      <span class="_step _1">Datos Personales</span> 
      <span class="_step _1">></span> 
      <span class="_step _3">Cotización de póliza</span>
    </div>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Nombres</mat-label>
    <input
      matInput
      placeholder="Nombres"
      formControlName="nombresMedico"
      required
    />
    <mat-error>
      {{ errorNombre() }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Apellidos</mat-label>
    <input
      matInput
      placeholder="Apellidos"
      formControlName="apellidosMedico"
      required
    />
    <mat-error>
      {{ errorApellido() }}
    </mat-error>
  </mat-form-field>
  <!-- traido de step-form -->
  <mat-form-field appearance="outline">
    <mat-label>Número celular</mat-label>
    <input
      (keydown)="bloquearLetras($event)"
      matInput
      placeholder="Número de celular"
      formControlName="numeroCelular"
      required
    />
    <mat-error>
      {{ errorCelular() }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Correo Electrónico</mat-label>
    <input
      matInput
      placeholder="Correo Electrónico"
      formControlName="correo"
      type="email"
      required
    />
    <mat-error>
      {{ errorCorreo() }}
    </mat-error>
  </mat-form-field>
  <div class="form-button-container">
    <button
      class="_btn _1"
      mat-raised-button
      [disabled]="contacto.invalid"
      (click)="enviarSobreTiForm()"
    >
      Siguiente
    </button>
  </div>
</form>
