<form [formGroup]="especialidadForm" class="_form _1">
  <div class="_head">
    <h4>Cotiza tu póliza de <br>Responsabilidad civil médica</h4>
    <div class="_steps">
      <span class="_step _1">Información médica</span> 
      <span class="_step _1">></span> 
      <span class="_step _2">Datos Personales</span> 
      <span class="_step _2">></span> 
      <span class="_step _3">Cotización de póliza</span>
    </div>
  </div>
  <!-- Autocomplete -->
  <mat-form-field appearance="outline">
    <mat-label>Escoje tu especialidad</mat-label>
    <input
      tab-directive
      type="text"
      placeholder="Escoje tu especialidad"
      aria-label="Number"
      matInput
      formControlName="especialidadMedico"
      [matAutocomplete]="especialidadAutocomplete"
      [value]="nombreEspecialidad"
      (keydown)="seleccionEspecialidadEnter($event)"
    />
    <mat-autocomplete
      #especialidadAutocomplete="matAutocomplete"
      (optionSelected)="escribirEspecialidad()"
      autoActiveFirstOption
    >
      <mat-option
        *ngFor="let especialidad of especialidadList | async"
        [value]="especialidad"
        (click)="enviarEspecialidad()"
      >
        {{ especialidad.nombre_especialidad }}
      </mat-option>
    </mat-autocomplete>
    <mat-error>
      {{ errorEspecialidad() }}
    </mat-error>
  </mat-form-field>

  <!--Agregado de "Tu Profesión"-->

  <mat-form-field appearance="outline" required>
    <mat-label>Escoge el tipo de procedimiento</mat-label>
    <mat-select formControlName="tipoMedico">
      <mat-option
        *ngFor="let tipoMedico of tipoMedicos"
        (click)="obtenerRcPorClase()"
        [value]="tipoMedico.clase_medico"
      >
        {{ tipoMedico.clase_medico.nombre_clase }}
      </mat-option>
    </mat-select>
    <!--<mat-hint align="end"> <a type="button" (click)="mostrarTextoTipoProcedimiento()">¿Qué tipo de procedimiento escojo?</a></mat-hint>-->
    <mat-error>
      {{ errorSeleccion() }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" required>
    <mat-label>Escoge el valor asegurado</mat-label>
    <mat-select formControlName="valorAsegurado">
      <mat-option
        *ngFor="let valor of valorAsegurado"
        [value]="valor.id_clase_rc_medico"
      >
        {{
          valor.rc_medico.numero_rc_medico | currency : "$" : "symbol" : "1.0-0"
        }}
      </mat-option>
    </mat-select>
    <!--<mat-hint align="end"> <a type="button" (click)="mostrarTextoValorAsegurado()">¿Qué es esto?</a></mat-hint>-->
    <mat-error>
      {{ errorSeleccion() }}
    </mat-error>
  </mat-form-field>
  <!--div>
    <mat-checkbox formControlName="autorizacion"
      >Acepto el
      <a (click)="atdp()" class="enlace-primario"
        >tratamiento de mis datos personales </a
      >así como los
      <a (click)="terminos()" class="enlace-primario"
        >términos y condiciones del sitio</a
      ></mat-checkbox
    >
  </div -->
  <div class="form-button-container">
    <button class="_btn _1"
      mat-raised-button
      [disabled]="especialidadForm.invalid"
      (click)="confirmarFormularioEspecialidad()"
    >
      Siguiente
    </button>
  </div>
  <div class="_foot">
    <p><small>Al hacer clic en cotizar, confirmas que aceptas nuestras <a data-bs-toggle="modal" data-bs-target="#policyPopup" >políticas de tratamiento de mis datos personales</a> y <a data-bs-toggle="modal" data-bs-target="#termsPopup" >términos y condiciones del sitio</a></small></p>
  </div>
</form>
