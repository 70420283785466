<div class="dialog-header">
    <h2 mat-dialog-title> <strong>Términos y condiciones</strong></h2>
    <div class="dialog-close-button">
        <button type="button" (click)="close()" class="close-dialog-button">
            <i class="material-icons">close</i>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <div class="dialog-content">
        <p>
            Para ASESORES LATINOS DE SEGUROS LTDA es muy importante proteger la información y los datos de sus Usuarios.
            Este
            documento contiene nuestra política general de tratamiento de información y datos personales, y tiene como
            fin
            comunicar qué información recolectamos en la prestación de nuestros servicios y para qué finalidades, cómo
            la
            usamos, cuándo la compartimos y cómo la protegemos, así como los derechos que le asisten a los titulares de
            la
            información y los procedimientos para ejercerlos.
        </p>
        <p>

            Reconocemos la importancia de la seguridad, privacidad y confidencialidad de los datos personales que
            nuestros
            Usuarios, proveedores y empleados nos suministran a través de diversos canales.
        </p>
        <p>
            Este documento es parte integral de los Términos y Condiciones de ASESORES LATINOS DE SEGUROS LTDA. Mediante
            la
            aceptación de los Términos y Condiciones el Usuario acepta igualmente las políticas aquí contenidas.
        </p>
        <p>
            Los titulares de los datos personales aceptan el tratamiento de sus datos personales conforme los términos
            de
            esta
            Política de Tratamiento De Datos Personales y nos autorizan a su tratamiento en los términos de esta
            política cuando proporciona los datos a través de nuestros puntos de atención o compra incluyendo Call Centers, o cuando
            adquiere o
            cuando usa cualquiera de nuestros productos o servicios, o cuando navega en nuestro sitio de web.
        </p>
        <p>
            <strong>
                Información de Contacto
            </strong>
        </p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA, es una sociedad constituida y domiciliada en Colombia, responsable del
            tratamiento
            de datos personales. Nuestros datos de contacto son:
        </p>
        <ul>
            <li>Dirección: Cra 25b # 69c-80 Lc 104</li>
            <li>E-mail: contacto@alseguros.co</li>
        </ul>
        <p>
            <strong>
                Información recolectada
            </strong>
        </p>
        <p>
            La Información que se le solicita al usuario incluye, mas no se limita a:
        </p>
        <ul>
            <li>Correo electrónico, teléfono fijo y celulares</li>
            <li>Nombres y apellidos, dirección, fecha de nacimiento, género, número telefónico.</li>
            <li>Tipo y número de identificación.</li>
            <li>Nacionalidad y país de residencia.</li>
            <li>Estado civil y/o parentesco.</li>
            <li>Profesión u oficio y compañía en la que labora y cargo</li>
            <li>Información de vehículo, tales como placas, modelo, valor, lugar de circulación</li>
            <li>Información sobre seguros cotizados.</li>
        </ul>
        <p>
            Con la finalidad de cumplir con las obligaciones del SARLAFT, los titulares de los datos personales deberán
            entregar
            información sensible, como datos biométricos (huella dactilar). Los titulares no estarán obligados en ningún
            evento
            a autorizar el tratamiento de datos sensibles, y por lo tanto la prestación de nuestros servicios no está
            condicionada a la entrega de esta información sensible, con excepción a la información requerida para
            diligenciamiento del SARLAFT que es un requisito legal para adquirir seguros, en caso de no entregarse esta
            información no podremos prestar nuestros servicios.
        </p>
        <p>
            Toda la información que recolectamos de nuestros clientes es compartida con las compañías de seguros por
            diferentes
            medios para obtener las propuestas o cotizaciones de seguros que, como intermediarios, presentaremos a los
            clientes
            prospectos. Por tal motivo, los titulares de datos personales, al aceptar esta política autorizan que su
            información
            personal que recolectamos y tratamos sea compartida con las compañías de seguros.
        </p>
        <p>
            La información suministrada permanecerá almacenada por el término máximo necesario para permitirnos el
            cumplimiento
            de las obligaciones legales y/o contractuales a su cargo especialmente en materia contable, contractual,
            fiscal
            y
            tributaria o por el tiempo necesario para atender las disposiciones aplicables a la materia de que se trate
            ya
            los
            aspectos administrativos, contables, fiscales, jurídicos e históricos de la información.
        </p>
        <p>
            Presumimos la veracidad de la información suministrada y no verificamos, ni asumimos la obligación de
            verificar
            la
            veracidad, vigencia, suficiencia y autenticidad de los datos que nos sean proporcionados. Por tanto, no
            asumimos
            responsabilidad por daños y/o perjuicios de toda naturaleza que pudieran tener origen en la falta de
            veracidad,
            vigencia, suficiencia o autenticidad de la información, incluyendo daños y perjuicios que puedan deberse a
            la
            homonimia o a la suplantación de la identidad.
        </p>
        <p>
            Al inscribirse y/o navegar en nuestra página web, nuestros clientes y/o el visitante manifiesta expresamente
            que
            conoce estas políticas y las acepta, y otorga su consentimiento para que se utilice su información.
        </p>

        <p>
            <strong>
                Seguridad y confidencialidad
            </strong>
        </p>

        <p>
            Tenemos establecidos políticas, procedimientos y estándares de seguridad de la información, cuyo objetivo es
            proteger y preservar la integridad, confidencialidad y disponibilidad de la información.
        </p>

        <p>
            <strong>
                Finalidades del tratamiento
            </strong>
        </p>

        <p>
            Si usted nos proporciona Datos Personales, nos autoriza para usar esta información para las finalidades
            señaladas en
            la respectiva solicitud en concordancia con lo previsto en esta Política de Tratamiento De Datos Personales,
            y
            no
            procederemos a transferir o divulgar la misma fuera de la Sociedad salvo que <strong>(i)</strong> usted nos
            autorice expresamente
            a
            hacerlo, <strong>(ii)</strong> sea necesario para permitir a nuestros contratistas, proveedores o agentes
            prestar los servicios
            que
            les hemos encomendado, <strong>(iii)</strong> la utilicemos para proporcionarle nuestros productos o
            servicios,
            <strong>(iv)</strong> sea
            entregada a
            las entidades que prestan servicios de marketing en nuestro nombre o a otras entidades con las cuales
            tenemos
            acuerdos de mercadeo conjunto, <strong>(v)</strong> tenga relación con una fusión, consolidación,
            adquisición,
            desinversión u
            otro
            proceso de restructuración, <strong>(vi)</strong> implementemos un contrato de transmisión de datos
            personales
            en los términos
            del
            Decreto 1377/13, o <strong>(vii)</strong> según sea requerido o permitido por la ley o para las finalidades
            desarrolladas en la
            presente Política de Tratamiento De Datos Personales.
        </p>

        <p>
            Al aceptar esta Política de Tratamiento De Datos Personales, nos autoriza para hacer tratamiento de la
            información
            recolectada para procesar, confirmar, cumplir y proveer nuestros servicios y/o productos, así como para
            promocionar
            y publicitar nuestras actividades, productos y servicios, realizar transacciones, efectuar reportes a las
            distintas
            autoridades administrativas de control y vigilancia nacionales o internacionales, autoridades de policía o
            autoridades judiciales, entidades bancarias y/o compañías aseguradoras, para fines administrativos internos
            y/o
            comerciales tales como, actualizar sus datos, investigación de mercados, encuestas de calidad de nuestros
            servicios,
            auditorías, reportes contables, análisis estadísticos, facturación, y ofrecimiento y/o reconocimiento de
            beneficios
            y nuevos productos de nuestros aliados.
        </p>

        <p>
            Con la aceptación de la presente Política de Tratamiento De Datos Personales, nuestros clientes, empleados y
            proveedores nos autorizan para hacerles llegar por distintos medios y vías (incluyendo correo electrónico,
            SMS o
            mensajes de texto, etc.) información de productos y servicios presentados en esta página web, y de ofertas
            de
            productos y servicios que creemos pueden serle de interés.
        </p>

        <p>
            Adicionalmente, nuestros clientes en su calidad de titulares de los datos recolectados, al aceptar esta
            política
            de
            privacidad, nos autorizan para:</p>
        <p>
            Utilizar la información recibida de ellos, para fines de mercadeo de sus productos y servicios, y de los
            productos y
            servicios de terceros con los que tengamos una relación de negocios.
        </p>
        <p>
            Compartir los datos personales con compañías de seguros con el fin de garantizar la prestación de nuestros
            servicios, la cotización de seguros y la expedición de las pólizas de seguros, así como compartir su
            información
            personal con compañías de seguros para que estos puedan ofrecer productos de interés a nuestros clientes y
            campañas
            de ventas cruzadas. En algunos de estos eventos, nosotros actuamos como encargados del tratamiento, siendo
            las
            compañías de seguros los responsables de tratamiento.
        </p>
        <p>
            Suministrar los datos personales a las autoridades de control y vigilancia de policía o judiciales, en
            virtud de
            un
            requerimiento legal o reglamentario y/o usar o revelar esta información y datos personales en defensa de sus
            derechos y/o su patrimonio en cuanto dicha defensa tenga relación con los productos y/o servicios
            contratados.
        </p>
        <p>
            Permitir el acceso a la información y datos personales a los auditores o terceros contratados para llevar a
            cabo
            procesos de auditoría interna o externa propios de la actividad comercial que desarrollamos.
        </p>

        <p>
            Consultar y actualizar los datos personales, en cualquier tiempo, con el fin de mantener actualizada dicha
            información.
        </p>
        <p>
            Contratar con terceros el almacenamiento y/o procesamiento de la información y datos personales para la
            correcta
            ejecución de los contratos celebrados con nosotros, bajo los estándares de seguridad y confidencialidad a
            los
            cuales
            estamos obligados.
        </p>
        <p>
            Transferir y transmitir los datos personales a países distintos de aquellos donde se recolecta la
            información,
            para
            lo cual procuraremos la protección de dichos datos conforme a los estándares de seguridad y confidencialidad
            establecidos en este documento.
        </p>
        <p>
            Transferir los datos personales a un eventual comprador de una de nuestras unidades de negocio, para lo cual
            brindaremos información oportuna a los titulares de los datos personales, para que éstos ejerzan los
            derechos de
            actualización, modificación y supresión de datos personales que les asisten conforme a la ley aplicable.
        </p>

        <p>
            En caso de venta, fusión, absorción, consolidación, cambio en el control societario, transferencia o cesión
            de
            activos a cualquier título, incluyendo, pero sin limitarse a la base de datos en la que conste la
            Información,
            reorganización o liquidación de la Sociedad, el titular de los datos nos autoriza a transferir la
            información.
        </p>

        <p>
            <strong>
                Modificaciones y vigencia de la Política de Tratamiento De Datos Personales
            </strong>
        </p>
        <p>

            Podremos modificar en cualquier momento los términos y condiciones de estas Política de Tratamiento De Datos
            Personales. Si decidimos introducir algún cambio material a nuestras Política de Tratamiento De Datos
            Personales, se
            informará en la página web y publicando una versión actualizada de las políticas de privacidad. Las bases de
            datos
            tendrán vigencia indefinida, de conformidad las finalidades y usos de la información. </p>

        <p>
            Esta política fue modificada y publicada en nuestro sitio web el 30 de abril de 2020 y entra en vigencia a
            partir de
            la fecha de publicación.
        </p>
    </div>
</div>