<app-navigation></app-navigation>
<section class="_cotizacion _i">
  <div class="container-xl">
    <div class="_head">
      <h2>Esta es tu cotización Mapfre Seguro Póliza <br class="d-none d-lg-block">de responsabilidad civil médica</h2>
    </div>
    <div class="_body">
      <div class="row">
        <div class="col-xl-6 _1">
          <div class="_in">
            <div class="_item _1">
              <div class="_title d-none d-lg-block">
                <h3>Sobre tu especialidad</h3>
              </div>
              <div class="_title d-lg-none">
                <h3>Información de tu especialidad</h3>
              </div>
              <div class="_content">
                <div class="_flex _1">
                  <div class="_i">
                    <p class="_h"><b>Especialidad</b></p>
                  </div>
                  <div class="_ii">
                    <p class="_p">{{especialidad}}</p>
                  </div>
                </div>
                <div class="_flex _2">
                  <div class="_i">
                    <p class="_h"><b>Tipo de procedimiento:</b></p>
                  </div>
                  <div class="_ii">
                    <p class="_p">{{clase}}</p>
                    <span class="_popover" (mouseenter)="onHover()" (mouseleave)="onLeave()">
                      <i class="bi bi-question-lg"></i>
                      <div class="_popover-brow _desk_view_clases">
                        <div class="_content-popover">
                          <h4>¿Cuáles son los tipos de procedimiento?</h4>
                          <p><b>CLASE 1: Sin Cirugía</b> <br>Aplica únicamente para consultas</p>
                          <p><b>CLASE 2: Con Cirugía Menor</b> <br>Son procedimiento quirúrgicos simples de bajo riesgo</p>
                          <p><b>CLASE 3: Procedimientos Menores y Mayores</b> </p>
                          <p><b>CLASE 4: Con Cirugía</b> </p>
                          <p><b>CLASE 5: Cirugía Mayor</b> <br>Intervenciones más complejas para abordar problemas de salud significativos, son todas aquellas que pongan en riesgo la vida del paciente</p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="_item _2">
              <div class="_title d-none d-lg-block">
                <h3>Sobre tu póliza</h3>
              </div>
              <div class="_title d-lg-none">
                <h3>Información sobre tu póliza</h3>
              </div>
              <div class="_content">

                <div class="_flex _1">
                  <div class="_i">
                    <p class="_h"><b>Valor asegurado:</b> 
                      <span class="_popover d-lg-none" (mouseenter)="onHover2()" (mouseleave)="onLeave2()">
                        <i class="bi bi-question-lg"></i>
                        <div class="_popover-brow _desk_view_clases2">
                          <div class="_content-popover">
                            <h4>¿Cómo afecta el valor asegurado al valor de mi póiliza?</h4>
                            <p>El valor asegurado constituye la cantidad máxima que la aseguradora se compromete a abonar en caso de tener que indemnizar a un paciente. A medida que este valor se incrementa, también lo hace el costo correspondiente de la póliza.</p>
                          </div>
                        </div>
                      </span></p>
                  </div>
                </div>
                <div class="_flex _2">
                  <div class="_form">
                    <button mat-raised-button (click)="cambiarValor(valorCotizacion.id_clase_rc_medico)" class="_btn _1 _outline fullwidth">
                    CAMBIAR VALOR ASEGURADO</button>
                    <span class="_popover d-none d-lg-block" (mouseenter)="onHover3()" (mouseleave)="onLeave3()">
                      <i class="bi bi-question-lg"></i>
                      <div class="_popover-brow _desk_view_clases3">
                        <div class="_content-popover">
                          <h4>¿Cómo afecta el valor asegurado al valor de mi póiliza?</h4>
                          <p>El valor asegurado constituye la cantidad máxima que la aseguradora se compromete a abonar en caso de tener que indemnizar a un paciente. A medida que este valor se incrementa, también lo hace el costo correspondiente de la póliza.</p>
                        </div>
                      </div>
                    </span>
                      
                  </div>
                </div>

              </div>
            </div>
            <div class="_item _3">
              <div class="_content">
                <div class="_flex _1">
                  <div class="_data">
                    <p class="_title">Gastos de defensa:</p>
                    <p class="_price">{{cuarentaPor| currency : '$' : 'symbol' : '1.0-0'}}</p>
                  </div>
                  <div class="_data">
                    <p class="_title">Responsabilidad civil actos médicos:</p>
                    <p class="_price">{{valorCotizacion.rc_medico.numero_rc_medico | currency : '$' : 'symbol' : '1.0-0'}}</p>
                  </div>
                  <div class="_data">
                    <p class="_title">Asistencia médica emergencia:</p>
                    <p class="_price">{{valorCotizacion.rc_medico.numero_rc_medico | currency : '$' : 'symbol' : '1.0-0'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 _2">
          <div class="_in">
            <div class="_card">
              <div class="_in">
                <div class="_price">
                  <p class="costo-mapfre">{{valorCotizacion.valor_rc | currency : '$' : 'symbol' : '1.0-0'}}/año</p>
                  <p class="_iva">Este valor incluye IVA</p>
                </div>
                <button mat-raised-button class="form-button _btn _1 _fulwidth" (click)="adquirirSeguro()"><span>Ingresa tus datos</span></button>
                <div class="_descripcion">
                  <p>Una vez revisada tu documentación por nuestro equipo de emisiones, podrás realizar el pago y recibirás los detalles a través de WhatsApp.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="_cotizacion _ii">
  <div class="container-xl">
    <div class="row">
      <div class="col-lg-4 _1 offset-lg-8">
        <div class="_in">
          <h3 class="_h3 _1">Protege tu auto como cuidas de tus pacientes</h3>
          <a class="_btn _2" href="https://api.whatsapp.com/send?phone=573124951031" target="_blank">
            <span>Obtén más información</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <figure class="_bg">
    <img src="assets/img/bgs/bg_cotizacion.jpg">
  </figure>
</section>
<app-footer></app-footer>