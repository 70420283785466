<app-navigation></app-navigation>
<main class="_nosotros">
	<section class="_i">
		<div class="row no-m">
			<div class="col-xl-6 _1">
				<div class="_info">
					<h1 class="_title">50 años de Compromiso y Protección en el Mundo de los Seguros en Colombia</h1>
					<p>Desde 1974, Asesores Latinos de Seguros, ha sido un referente en el mundo de los seguros en Colombia. Fundada por Humberto García, hemos mantenido un compromiso sólido con la protección y la confianza de nuestros clientes.</p>
				</div>
			</div>
			<div class="col-xl-6 _2">
				<figure class="_bg">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/fSYvjXKlfXE?si=XyiGuX7lPupJePZi&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</figure>
			</div>
		</div>
	</section>
	<section class="_ii">
		<div class="container-lg">
			<div class="row">
				<div class="col-lg-6 _1">
		      <div class="splide _splide_bg">
		        <div class="splide__track">
		          <div class="splide__list">
		            <div class="splide__slide">
		            	<figure>
		            		<img src="assets/img/pics/nosotros_2.png">
		            	</figure>
		            </div>
		            <div class="splide__slide">
		            	<figure>
		            		<img src="assets/img/pics/nosotros_3.png">
		            	</figure>
		            </div>
		            <div class="splide__slide">
		            	<figure>
		            		<img src="assets/img/pics/nosotros_4.png">
		            	</figure>
		            </div>
		            <div class="splide__slide">
		            	<figure>
		            		<img src="assets/img/pics/nosotros_5.png">
		            	</figure>
		            </div>
		          </div>
		        </div>
		      </div>
				</div>
				<div class="col-lg-6 _2">
					<div class="_info">
						<div class="_in">
							<div class="_text">
								<p>Nuestros inicios comenzaron en Colseguros en 1968 que nos llevaron a establecer nuestra propia agencia, ofreciendo soluciones de seguridad efectivas y confiables. Desde entonces, hemos crecido y evolucionado, abriendo nuevos puntos de venta y asociándonos con líderes del sector como Mapfre.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="_iii">
		<div class="container-lg">
			<div class="row">
				<div class="col-lg-6 _1">
					<div class="_content">
						<div class="_one">
							<div class="_title">
								<h2>Buscamos tu <br>tranquilidad ante todo</h2>
							</div>
							<div class="_text">
								<p>Hoy en día, seguimos comprometidos con nuestra misión original: brindar tranquilidad y protección a nuestros clientes. Nuestro legado de integridad y atención personalizada nos distingue en un mercado competitivo.</p>
							</div>
						</div>
						<div class="_two d-none d-xl-block">
							<p>En Alseguros, tu seguridad es nuestra prioridad. Únete a nosotros y experimenta la diferencia.</p>
						</div>
					</div>
						
				</div>
			</div>
		</div>
		<figure class="_pics">
			<img class="_1" src="assets/img/pics/pic_nosotros_1.png">
			<img class="_2" src="assets/img/pics/pic_nosotros_2.png">
			<img class="_3" src="assets/img/pics/pic_nosotros_3.png">
			<img class="_4" src="assets/img/pics/pic_nosotros_4.png">
			<img class="_5" src="assets/img/pics/pic_nosotros_5.png">
			<img class="_6" src="assets/img/pics/pic_nosotros_6.png">
		</figure>
		<div class="container-lg d-xl-none">
			<div class="row">
				<div class="col-lg-6 _1">
					<div class="_content">
						<div class="_two">
							<p>En Alseguros, tu seguridad es nuestra prioridad. Únete a nosotros y experimenta la diferencia.</p>
						</div>
					</div>
						
				</div>
			</div>
		</div>
	</section>
	<section class="_iv">
		<div class="container-lg">
			<div class="_form">
				<app-formulario-cotizacion (formularioCotizacionFinalizado)="formularioCotizacionFinalizado($event)" ></app-formulario-cotizacion>
			</div>
		</div>
		<figure class="_bg">
			<img src="assets/img/bgs/bg_Grandient_effect.png">
		</figure>
	</section>
</main>
<app-footer></app-footer>

