<app-navigation></app-navigation>

<section class="_adjuntar _1">
  <div class="container-sm">
    <div class="_in">
      <div class="_data">
        <div *ngIf="errorCedula != '' || errorTarProf != '' || errorDiploma != '' || errorActa != ''">
          <div class="error-alert">
            <p>{{errorCedula}}</p>
            <p>{{errorTarProf}}</p>
            <p>{{errorDiploma}}</p>
            <p>{{errorActa}}</p>
          </div>
        </div>
        <div class="_head">
          <h2>Ya casi tienes tu póliza</h2>
          <p>Para poder conseguir tu Póliza de Responsabilidad Civil profesional Médica de MAPFRE, deberás otorgar la siguiente documentación:</p>
        </div>
        <div class="_body">
          <div class="_form">
            <form [formGroup]="adjuntarDocumentosForm" class="_form">
              <div class="row">
                <div class="col-lg-6">
                  <div class="input-container">
                    <label for="copiaCedula" [ngClass]="fileSelectedCed">Copia de tu cédula de ciudadanía</label>
                    <button class="_btn _1 _outline" type="button">
                      <span>Cargar documento</span>
                      <input type="file" formControlName="copiaCedula" name="copiaCedula" id="copiaCedula" class="input-file"
                        accept="image/x-png,image/jpeg,application/pdf" (change)="onFileChangeCel($event)" />
                    </button>
                    <p class="_hide"> {{ fileNameCed }} </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-container">
                    <label for="copiaTarjetaProfesional" [ngClass]="fileSelectedTarProf">Copia de tu tarjeta profesional</label>
                    <button class="_btn _1 _outline" type="button">
                      <span>Cargar documento</span>
                      <input type="file" formControlName="copiaTarjetaProfesional" name="copiaTarjetaProfesional"
                          id="copiaTarjetaProfesional" class="input-file" accept="image/x-png,image/jpeg,application/pdf"
                          (change)="onFileChangeTarProf($event)" />
                    </button>
                    <p class="_hide"> {{ fileNameTarProf }} </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-container">
                    <label for="diplomaGrado" [ngClass]="fileSelectedDipGrad">Diploma de grado (En caso de tener especialidad incluir documento)</label>
                    <button class="_btn _1 _outline" type="button">
                      <span>Cargar documento</span>
                      <input type="file" class="input-file" formControlName="diplomaGrado" name="diplomaGrado"
                        id="diplomaGrado" accept="image/x-png,image/jpeg,application/pdf"
                        (change)="onFileChangeDipGrad($event)" />
                    </button>
                    <p class="_hide"> {{ fileNameDipGrad }} </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-container">
                    <label for="actaGrado" [ngClass]="fileSelectedActGrad">Acta de grado (En caso de tener especialidad incluir documento)</label>
                    <button class="_btn _1 _outline" type="button">
                      <span>Cargar documento</span>
                      <input type="file" class="input-file" formControlName="actaGrado" name="actaGrado" id="actaGrado"
                        accept="image/x-png,image/jpeg,application/pdf" (change)="onFileChangeActGrad($event)" />
                    </button>
                    <p class="_hide"> {{ fileNameActGrad }} </p>
                  </div>
                </div>
              </div>
              <div class="_actions">
                <button mat-raised-button class="form-button" [disabled]="adjuntarDocumentosForm.invalid" color="primary" class="_btn _1" (click)="sendDocumentosForm()">Confirma tu póliza</button>
                <button mat-raised-button class="form-button" [disabled]="adjuntarDocumentosForm.invalid" class="_btn _1 _outline" (click)="adjuntarDocumentosDespues()">Cargar documentos luego</button>
                <br>
                <p class="_note">Recuerda que si no cuentas con los documentos, te enviaremos un correo con un enlace para que puedas subirlos después.No olvides revisar tu bandeja de spam.</p>
              </div>
            </form>
          </div>
        </div>
          
      </div>
    </div>
  </div>
</section>



<app-footer></app-footer>