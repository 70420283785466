import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CambioRcComponent } from '../cambio-rc/cambio-rc.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RCMedico, ClaseMedico } from '../interfaces/PolizaMedConsultas';
import { ConsultasPolizaMedicoService } from './../services/consultas-poliza-medico.service';
import { PersonaSolicitudesService } from './../services/persona-solicitudes.service';

interface InterfaceGlobal {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-mostrar-cotizacion',
  templateUrl: './mostrar-cotizacion.component.html',
  styleUrls: ['./mostrar-cotizacion.component.scss'],
})
export class MostrarCotizacionComponent implements OnInit {
  // Array valor cotizaciones
  cotizaciones: RCMedico[] = [];
  especialidad: string;
  clase: string;
  idValorCotizado: number;
  cuarentaPor: number;
  dialogConfig = new MatDialogConfig();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private consultaMedService: ConsultasPolizaMedicoService,
    private personaService: PersonaSolicitudesService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  valorCotizacion: RCMedico = null;
  
  ngOnInit(): void {
    this.especialidad = this.consultaMedService.getEspecialidadNomb();
    this.clase = this.consultaMedService.getClaseNomb();
    this.valorCotizacion = this.consultaMedService.getValorSeleccionado();
    this.cotizaciones = this.consultaMedService.getArregloValoresRc();
    this.cuarentaPor =
      parseInt(this.valorCotizacion.rc_medico.numero_rc_medico) * 0.4;
  }

  cambiarValor(idClaseRc) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;

    this.dialogConfig.data = {
      idClaseRc,
      arregloCotizaciones: this.cotizaciones,
    };

    const dialogRef = this.dialog.open(CambioRcComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      this.valorCotizacion = this.consultaMedService.getValorSeleccionado();
      this.cuarentaPor =
        parseInt(this.valorCotizacion.rc_medico.numero_rc_medico) * 0.4;
    });
  }

  adquirirSeguro() {
    this.personaService.anadirInformacionCotizacionCorreo(
      this.consultaMedService.getInformacionCotizacion()
    );
    this.router.navigate(['identificacion-medico']);
  }

  onHover() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases');
    if (targetElement) {
      this.renderer.addClass(targetElement, 'on');
    }
  }

  onLeave() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases');
    if (targetElement) {
      this.renderer.removeClass(targetElement, 'on');
    }
  }



  onHover2() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases2');
    if (targetElement) {
      this.renderer.addClass(targetElement, 'on');
    }
  }

  onLeave2() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases2');
    if (targetElement) {
      this.renderer.removeClass(targetElement, 'on');
    }
  }

  onHover3() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases3');
    if (targetElement) {
      this.renderer.addClass(targetElement, 'on');
    }
  }

  onLeave3() {
    const targetElement = this.el.nativeElement.querySelector('._desk_view_clases3');
    if (targetElement) {
      this.renderer.removeClass(targetElement, 'on');
    }
  }
}
