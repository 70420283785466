import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoIdentificacion, Ciudad } from '../interfaces/Consultas';
import { ConsultasService } from '../services/consultas.service';
import { ConsultasPolizaMedicoService } from '../services/consultas-poliza-medico.service';
import { PersonaSolicitudesService } from '../services/persona-solicitudes.service';
import {
  SarlafPreguntas,
  RCMedico,
  ClaseMedico,
} from '../interfaces/PolizaMedConsultas';
import { InformacionCompletaFinal } from '../interfaces/PersonaCliente';

@Component({
  selector: 'app-identificacion',
  templateUrl: './identificacion.component.html',
  styleUrls: ['./identificacion.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class IdentificacionComponent implements OnInit {
  @ViewChild('bit', { static: false }) bit: ElementRef;
  @ViewChild('noteYesNo', { static: false }) noteYesNo: ElementRef;

  identificacionForm: FormGroup;
  preguntasAdicionalesForm: FormGroup;
  informacionR: InformacionCompletaFinal;
  especialidad: string;
  clase: string;
  // idValorCotizado: number;
  cuarentaPor: number;

  /**
   * @description Array de tipo de médicos en BD
   */
  tipoDocumento: TipoIdentificacion[] = [];

  /**
   * @description Variable que permite definir la fecha mínima para iniciar la selección en el calendario
   * @type Date
   */
  minDate: Date;

  /**
   * @description Variable que permite definir la fecha máxima de selección en el calendario
   * @type Date
   */
  maxDate: Date;

  userId: string = '';
  preguntas: SarlafPreguntas;

  /**
   * @description Array de ciudades
   */
  ciudades: Ciudad[] = [];
  ciudadesList: Observable<Ciudad[]>;

  /**
   * @description Valor que ingresa el usuario para filtrar
   */
  nombreCiudad: string;
  PreguntasAdicionales: boolean = false;
  FormIdentifiacion: boolean = true;

  private routeSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private consultasService: ConsultasService,
    private polizaConsultasService: ConsultasPolizaMedicoService,
    private consultaMedService: ConsultasPolizaMedicoService,
    private personaService: PersonaSolicitudesService,
    private renderer: Renderer2
  ) {}

  valorCotizacion: RCMedico = null;


  onRadioChange(event: any): void {
    const value = event.target.value;
    if (this.bit && this.bit.nativeElement) {
      if (value === '1') {
        this.renderer.setStyle(this.bit.nativeElement, 'left', '.5rem');
        this.updateVisibility('1');
      } else if (value === '2') {
        this.renderer.setStyle(
          this.bit.nativeElement,
          'left',
          'calc(100% - 2.5rem)'
        );
        this.updateVisibility('2');
      }
    }
  }

  updateVisibility(value: string): void {
    const yesSpan = this.bit.nativeElement.querySelector('._yes');
    const noSpan = this.bit.nativeElement.querySelector('._no');
    if (value === '1') {
      this.renderer.setStyle(yesSpan, 'display', 'inline');
      this.renderer.setStyle(noSpan, 'display', 'none');
      this.renderer.setStyle(this.noteYesNo.nativeElement, 'display', 'block');
    } else if (value === '2') {
      this.renderer.setStyle(yesSpan, 'display', 'none');
      this.renderer.setStyle(noSpan, 'display', 'inline');
      this.renderer.setStyle(this.noteYesNo.nativeElement, 'display', 'none');
    }
  }

  ngOnInit(): void {
    this.especialidad = this.consultaMedService.getEspecialidadNomb();
    this.clase = this.consultaMedService.getClaseNomb();
    this.valorCotizacion = this.consultaMedService.getValorSeleccionado();

    /**
     * @description Declaración de campos para cada formulario y sus validaciones
     */
    this.identificacionForm = this.formBuilder.group({
      lugarTrabajo: ['', Validators.required],
      ciudad: ['', Validators.required],
      preguntaSiete: [''],
    });

    this.consultasService.getCiudadesCirculacion().subscribe((ciudades) => {
      ciudades.map((ciudad) => {
        this.ciudades.push(ciudad);
      });
      /**
       * @description Permite filtrar las ciudades a medida que el usuario escribe en el input
       */
      this.ciudadesList = this.identificacionForm.controls[
        'ciudad'
      ].valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.value)),
        map((nombreEspecialidad) =>
          nombreEspecialidad
            ? this._filtroCiudad(nombreEspecialidad)
            : this.ciudades.slice()
        )
      );
    });
  }
  /**
   * @description Mostrar valores de la interface con Autocompletado
   * @param marca
   */
  displayEspecialidad(ciudad: Ciudad): string {
    return ciudad && ciudad.nombre_ciudad ? ciudad.nombre_ciudad : '';
  }
  /**
   * @description Permite filtar la especialidad escrita por el usuario dentro del listado
   * @param nombreMarca
   */
  private _filtroCiudad(nombreCiudad: string): Ciudad[] {
    const letraFiltro = nombreCiudad.toLowerCase();
    return this.ciudades.filter((option) =>
      option.nombre_ciudad.toLowerCase().includes(letraFiltro)
    );
  }

  /**
   * Este método imprime en el input type text el texto de la opción seleccionada por el usuario
   */
  escribirCiudad() {
    this.nombreCiudad =
      this.identificacionForm.controls['ciudad'].value.nombre_ciudad;
  }

  /**
   * @method
   * @description Permite imprimir los mensajes de error, cuando los campos del formulario no cumplen con las validaciones
   * @returns Mensajes de error que se imprimirán en HTML bajo el campo de comentario
   */
  errorDocumento() {
    return 'Debes seleccionar un documento para continuar';
  }
  errorNumeroDocumento() {
    return 'Debes ingresar tu número de documento para continuar';
  }

  errorFecha() {
    return 'Selecciona una fecha para continuar';
  }

  errorLugarTrabajo() {
    return 'Ingresa tu lugar de trabajo para continuar';
  }

  errorDireccion() {
    return 'Ingresa tu dirección para continuar';
  }

  adjuntarDocumentos() {
    // this.enviarIdentificacionForm();
    this.personaService.anadirRespuestaPreguntas(
      {
        pregunta_7: this.identificacionForm.controls['preguntaSiete'].value === '1'
        ? true
        : false
      }
    );

    this.polizaConsultasService.setInfoAdicional(
      this.identificacionForm.controls['lugarTrabajo'].value,
      this.identificacionForm.controls['ciudad'].value.codigo_ciudad,
      'No especificada',
      
    );
    this.personaService
      .anadirInformacionAdicional(
        this.polizaConsultasService.getInformacionCotizacion()
      )
      .subscribe((rta) => {});
    this.personaService.obtenerUrlDocumentos().subscribe((respuesta) => {
      this.userId = respuesta.url;
      this.router.navigate(['adjuntar-documentos/' + this.userId], {
        relativeTo: this.route.parent,
      });
    });
  }
}

/**
 * @method
 * @description Este método permite validar si es posible autocompletar la opción escrita por el usuario en el input, de lo contrario, retornará un mensaje de error como 'Valor inexistente'
 * @param Services
 */
export function autocompleteValidator(Services: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const index = Services.findIndex((Service) => {
      return new RegExp('^' + Service.viewValue + '$').test(
        control.value.viewValue
      );
    });
    return index < 0 ? { valorInexistente: { value: control.value } } : null;
  };
}
