<div class="dialog-header">
    <h2 mat-dialog-title> <strong>Política de tratamiento de datos MAPFRE</strong></h2>
    <div class="dialog-close-button">
        <button type="button" (click)="close()" class="close-dialog-button">
            <i class="material-icons">close</i>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <div class="dialog-content">
        <h3><strong>Condiciones generales</strong></h3>
        <p>
            Al acceder a <span class="terms">https://www.alseguros.co</span> y a los materiales e información aquí
            contenidos,
            usted reconoce haber leído,
            comprendido y aceptado los términos y condiciones de uso que se describen a continuación, y acepta obligarse
            conforme a los mismos. </p>
        <strong>Uso del sitio de Alseguros.co</strong>
        <p>
            El Sitio de ASESORES LATINOS DE SEGUROS LTDA, <span class="terms">https://www.alseguros.co</span>, tiene por
            objeto
            ofrecer información general
            sobre precios y condiciones de los seguros incluidos. </p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA lo autoriza a usted a consultar, revisar, usar y acceder a esta Página Web,
            únicamente para la eventual adquisición del Producto o Servicio. </p>
        <p>
            Sin embargo, la información contenida no debe ser interpretada como una solicitud u oferta y las condiciones
            están
            sujeto a confirmación final del Asegurador. Si bien intentamos proporcionar información actualizada, no
            asumimos
            la
            obligación de actualizar la información que se ofrece en este sitio. </p>
        <h3><strong>Contenido de la página</strong></h3>
        <p>
            En la Página Web se ofrece información proporcionada por MAPFRE SEGUROS sobre los Productos o Servicios
            ofrecidos
            por tal Aseguradora. La Aseguradora es la única y exclusiva responsable de la información, las prestaciones,
            y
            la
            entrega de los Productos o Servicios presentadas en la presente Página Web. Las prestaciones y entrega de
            los
            Productos o Servicios de la Aseguradora se rigen por sus respectivas Condiciones Generales de Contratación y
            las
            leyes que les sean aplicables. La Sociedad no está obligada a la realización de las prestaciones de los
            Productos o
            Servicios de los Clientes ni a la entrega de estos Productos o Servicios.</p>
        <p>
            El Usuario, es el responsable por la veracidad, calidad y exactitud de los datos registrados en esta Página
            Web
            y
            por las consecuencias de incluir o colocar información errada, parcial, incompleta, fraccionada o que
            induzca a
            error, o sin autorización legal o del titular. Ni el Usuario ni el Asegurador podrán: (i) incluir o reportar
            información incompleta, difamatoria, falsa, inexacta, desactualizada, incomprobable y incomprensible; (ii)
            incluir
            material que sea obsceno, difamatorio, abusivo, amenazante u ofensivo para cualquier otro Usuario o
            cualquier
            otra
            persona o entidad.</p>
        <h3><strong>Política De Tratamiento De Datos Personales</strong></h3>
        <p>
            Para usar o acceder a la presente página, los Usuarios deberán facilitar determinados datos que podrán ser
            considerados por las normas aplicables como datos personales tales como una dirección válida de correo
            electrónico y
            otro tipo de datos descritos en la Política de Tratamiento De Datos Personales. </p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA no se responsabiliza por imprecisiones, errores u omisiones en la
            información
            suministrada. Las comparaciones se realizarán de acuerdo a la información suministrada enteramente por el
            Usuario.
        </p>
        <p>
            El Usuario certifica que la información suministrada es precisa y libre de errores. </p>
        <p>
            Con la aceptación de estos Términos y Condiciones, el Usuario otorga su consentimiento y autorización libre,
            previo,
            y expreso, a ASESORES LATINOS DE SEGUROS LTDA. a almacenar, administrar, acceder, usar y tratar en cualquier
            manera
            su Información Personal para los fines establecidos en las Política de Tratamiento De Datos Personales y a
            suministrar o revelar su Información Personal a la Aseguradora y a terceras personas para los fines
            determinados
            en
            la Política de Tratamiento De Datos Personales. La Política de Tratamiento De Datos Personales hace parte
            integral
            de estos Términos y Condiciones.</p>
        <p>
            Usted también está de acuerdo que ASESORES LATINOS DE SEGUROS LTDA podrá utilizar con cualquier finalidad
            las
            ideas,
            conceptos, conocimientos o técnicas que usted presente a través de la página u otro canal de comunicación.
        </p>
        <h3><strong>Cambios en el Sitio</strong></h3>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA se reserva el derecho de modificar en cualquier momento el alcance y las
            funciones
            de su página web, de limitarla o cancelarla. Los trabajos de mantenimiento, la continuación del desarrollo
            y/o
            otras
            molestias pueden limitar y/o parcialmente interrumpir las posibilidades de uso. Bajo determinadas
            circunstancias
            se
            pueden producir pérdidas de datos. La Sociedad no asume por ello garantía alguna sobre la disponibilidad del
            servicio o la ausencia de molestias técnicas o pérdidas de datos. </p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA, puede modificar estos Términos y Condiciones en cualquier momento. Usted
            debe
            visitar esta Página Web periódicamente para revisar los Términos y Condiciones.</p>
        <p>
            <strong>Territorio</strong>
        </p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA no asegura que esta Página Web y su contenido pueda ser legalmente usado o
            accedido
            fuera del territorio de Colombia. El acceso y uso puede no ser legal por ciertas personas o en ciertos
            países.
            Si
            usted usa o tiene acceso a esta Página Web desde un lugar ubicado fuera del territorio de Colombia, lo hace
            bajo
            su
            propio riesgo y es responsable del cumplimiento de las leyes aplicables en su jurisdicción. </p>
        <p><strong>Terminación</strong></p>
        <p>
            ASESORES LATINOS DE SEGUROS LTDA se reserva el derecho, a su exclusiva discreción, de borrar toda la
            información
            que
            usted haya incluido en esta Página Web incluyendo sus datos personales y de terminar inmediatamente su
            acceso y
            uso
            a esta página web o a determinados servicios ante el incumplimiento por su parte de estos Términos y
            Condiciones
            o
            ante la imposibilidad de verificar o autenticar cualquier información que usted haya presentado en su forma
            de
            registro para acceder a esta Página Web.
        </p>

    </div>
</div>